<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <el-form
        @submit.prevent="submitForm()"
        :model="orderMappingOptions"
        ref="formRef"
        class="w-100"
      >
        <div class="row">
          <h4>{{ orderImportConstants.ORDER_DETAILS }}</h4>
          <div class="col-md-3" v-for="(header, index) in orderMappingOptions.orderData" :key="index">
            <div class="col-md-8" v-if="index != 'order_extrafields'">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9" v-if="index != 'order_extrafields'"> 
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="orderMappingOptions.orderData[index]" type="text" :placeholder="formatText(index)"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.CUSTOMER_DETAILS }}</h4>
          <div class="col-md-3" v-for="(header, index) in orderMappingOptions.customerData" :key="index">
            <div class="col-md-8" v-if="index != 'customer_extrafields'">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9" v-if="index != 'customer_extrafields'"> 
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="orderMappingOptions.customerData[index]" type="text" :placeholder="formatText(index)"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.SHIPPING_DETAILS }}</h4>
          <div class="col-md-3" v-for="(header, index) in orderMappingOptions.shippingData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="orderMappingOptions.shippingData[index]" type="text" :placeholder="formatText(index)"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.BILLING_DETAILS }}</h4>
          <div class="col-md-3" v-for="(header, index) in orderMappingOptions.billingData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="orderMappingOptions.billingData[index]" type="text" :placeholder="formatText(index)"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.BLIND_DETAILS }}</h4>
          <div class="col-md-3" v-for="(header, index) in orderMappingOptions.blindData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" v-model="orderMappingOptions.blindData[index]" type="text" :placeholder="formatText(index)"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.PRODUCT_DETAILS }}</h4>
          <div class="col-md-3" v-for="(header, index) in orderMappingOptions.productData" :key="index">
            <div class="col-md-8" v-if="index != 'product_extrafields'">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9" v-if="index != 'product_extrafields'"> 
              <div class="d-flex align-items-center">
                <div class="me-5">
                  <el-form-item :prop="index">
                    <el-input size="large" @keyup="updateValidationKeyValue(orderMappingOptions.productData[index],index)" v-model="orderMappingOptions.productData[index]" type="text" :placeholder="formatText(index)"/>
                  </el-form-item>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-9">
          <div class="col-md-12 mb-5 mt-12">
            <button type="button" class=" btn btn-primary me-2" :disabled="loading" @click="submitForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </button>
            <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{generalConstants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
            <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'trading-partners' })">{{generalConstants.CANCEL}}</button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter,useRoute } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "order-mapping",
  components: {
    Datatable,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const loadingback = ref(false);
    const disabled = ref(false);
    const formRef = ref(null);
    const generalConstants = globalConstant.general;
    const orderImportConstants = globalConstant.orderImport;
    const tradingPartnerConstants = globalConstant.tradingPartner;
    const validateOrderMappingField = ref({order_id: "", customer_email:"", shipping_name:"", shipping_address_1:"", shipping_address_2:"", shipping_city:"", shipping_postcode:"", shipping_state:"", shipping_state_code:"",shipping_country:"", shipping_country_code:"",shipping_phone:"",subtotal:"", total_amount:"", payment_method:"", product_sku: "", product_quantity: ""});

    const checkValidation = (val) => {
      if(val in validateOrderMappingField.value){
        return true;
      }else{
        return false;
      }
    }

    const updateValidationKeyValue = (val,key) => {
      validateOrderMappingField.value[key] = val;
    }

    const orderMappingOptions = reactive({
      orderData: {
        order_id: "",
        subtotal: "",
        total_amount: "",
        payment_method: "",
        order_weight: "",
        order_shipping_days: "",
        order_production_days: "",
        invoice_number: "",
        production_due_date: "",
        order_date: "",
        order_due_date: "",
        payment_status: "",
        transaction_id: "",
        payment_date: "",
        order_status: "",
        shipping_charges: "",
        blind_shipping_charges: "",
        discount: "",
        tax: "",
        payment_processing_fees: "",
        order_extrafields: []
      },
      productData: {
        product_sku: "",
        product_quantity: "",
        order_product_id: "",
        product_name: "",
        product_size_name: "",
        product_width: "",
        product_height: "",
        product_unit: "",
        product_weight: "",
        product_price: "",
        product_shipping_price: "",
        product_discount: "",
        product_tax: "",
        product_subtotal: "",
        product_production_days: "",
        product_shipping_days: "",
        product_status: "",
        product_extrafields: []
      },
      customerData: {
        customer_email: "",
        customer_name: "",
        customer_company: "",
        customer_phone: "",
        corporate_name: "",
        corporate_department_name: "",
        customer_extrafields: []
      },
      shippingData: {
        shipping_name: "",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_city: "",
        shipping_state: "",
        shipping_state_code: "",
        shipping_country: "",
        shipping_country_code: "",
        shipping_postcode: "",
        shipping_phone: "",
        shipping_company: "",
        shipping_method: "",
        shipping_type: "",
        shipping_type_key: "",
      },
      billingData: {
        billing_name: "",
        billing_company: "",
        billing_address_1: "",
        billing_address_2: "",
        billing_city: "",
        billing_postcode: "",
        billing_state: "",
        billing_state_code: "",
        billing_country: "",
        billing_country_code: "",
        billing_phone: ""
      },
      blindData: {
        blind_name: "",
        blind_company: "",
        blind_address_1: "",
        blind_address_2: "",
        blind_city: "",
        blind_postcode: "",
        blind_state: "",
        blind_state_code: "",
        blind_country: "",
        blind_country_code: "",
        blind_phone: "",
        blind_shipping: ""
      },
    });

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "save_back") {
        submitForm(formRef, "save_back", true);
      } else if (actionName == "cancel") {
        router.push({ name: "trading-partners" });
      }
    });

    //Get order mapping data
    const getProductMappingData = async () => {
      await ApiService.post("orders/get-order-mapping-data", { tradingPartnerId: route.params.id })
        .then(({ data }) => {
          if(data.data.order_mapping != null){
            for (const key in orderMappingOptions.orderData) {
              orderMappingOptions.orderData[key] = data.data.order_mapping.orderData[key];
              if(key in validateOrderMappingField.value){
                validateOrderMappingField.value[key] = data.data.order_mapping.orderData[key];
              }
            }
            for (const key in orderMappingOptions.productData) {
              orderMappingOptions.productData[key] = data.data.order_mapping.productData[key];
              if(key in validateOrderMappingField.value){
                validateOrderMappingField.value[key] = data.data.order_mapping.productData[key];
              }
            }
            for (const key in orderMappingOptions.shippingData) {
              orderMappingOptions.shippingData[key] = data.data.order_mapping.shippingData[key];
              if(key in validateOrderMappingField.value){
                validateOrderMappingField.value[key] = data.data.order_mapping.shippingData[key];
              }
            }
            for (const key in orderMappingOptions.billingData) {
              orderMappingOptions.billingData[key] = data.data.order_mapping.billingData[key];
              if(key in validateOrderMappingField.value){
                validateOrderMappingField.value[key] = data.data.order_mapping.billingData[key];
              }
            }
            for (const key in orderMappingOptions.blindData) {
              orderMappingOptions.blindData[key] = data.data.order_mapping.blindData[key];
              if(key in validateOrderMappingField.value){
                validateOrderMappingField.value[key] = data.data.order_mapping.blindData[key];
              }
            }
            for (const key in orderMappingOptions.customerData) {
              orderMappingOptions.customerData[key] = data.data.order_mapping.customerData[key];
              if(key in validateOrderMappingField.value){
                validateOrderMappingField.value[key] = data.data.order_mapping.customerData[key];
              }
            }
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    }

    //Submit form for product mapping data
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
      ApiService.post('orders/order-mapping', { 
        tradingPartnerId: route.params.id,
        mappingData: orderMappingOptions,
        validateOrderMappingField: validateOrderMappingField.value
        })
      .then(({ data }) => {
        notificationFire(data.message, "success");
        if(btnName == "save_back"){
          router.push({ name: "trading-partners" });
        }
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
      })
      .catch((error) => {
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
        let response = error.response;
        if (response != "") {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false }, {"name": generalConstants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": generalConstants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }

    //Set breadcrumbs and get the order mapping data
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('trading-partners'), "path": "trading-partners"}, { name: formatText(route.name), active: true }]);
      await getProductMappingData();
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })

    return {
      loading,
      loadingback,
      disabled,
      formRef,
      submitForm,
      generalConstants,
      tradingPartnerConstants,
      orderMappingOptions,
      orderImportConstants,
      validateOrderMappingField,
      checkValidation,
      formatText,
      updateValidationKeyValue
    };
  },
};
</script>
